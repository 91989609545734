@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@400;500;700&display=swap');

body {
  margin: 0;
  font-family: 'Dosis', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #FAFAFA url(img/esfera-bg.svg) no-repeat fixed center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul { 
  list-style: none;
  padding: 0;
  margin: 0;
}

h4 {
  margin-bottom: 0;
}


